.navbar{
    display: flex;
    flex: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.1rem;
    position: fixed;
    width: 100%;
    min-height: 75px;
    background-color: #333;
    color: #fff;
    z-index: 1000000;
    top: 0;
}
.navbar_logo{
    font-size: 25px;
    padding-left: 15px;
}
.navbar_logo:hover{
    cursor: pointer;
}
.navbar_links{
    display: flex;
    font-size: 25px;
    gap: 150px;
}
.navbar_link:hover{
    color: #eee;
    cursor: pointer;
}
.navbar_link{
    padding: 0 0.3rem;
    color: inherit;
}
.navbar_burger{
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    color: inherit;
}
.link:hover{
color: black;
font-weight: 500;
}
@media screen and (max-width: 1340px){
    .navbar_links{
        gap: 100px;
    }
}

@media screen and (max-width: 1136px){
    .navbar_links{
        gap: 50px;
    }
}

@media screen and (max-width: 858px){
    .navbar_links{
        gap: 20px;
    }
}
@media screen and (max-width: 795px){
    .navbar_links{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: -100vw;
        bottom: 0;
        height: 100vh;
        width: 0;
        padding: 0.5rem;
        visibility: hidden;
        background-color: #000;
        transition: all .8s ease-out;
    }
    .navbar_link:hover{
        cursor: pointer;
        text-decoration: none;
        color: #333;
    }
    .navbar{
        min-height: 60px;
    }
    .show-nav .navbar_links{
        right: -0vw;
        width: 100vw;
        visibility: visible;
    }
    .navbar_item{
        margin: 1rem;
    }
    /* bonus */
    .navbar_item::after{
        content: "";
        display: block;
        margin: 0 auto;
        width: 3vw;
        height: 1px;
        background: #fff;
    }
    /*-------------*/

    .navbar_link{
        display: block;
        padding: 1.5rem;
        font-size: 5vw;
    }
    .navbar_burger{
        display: block;
        position: fixed;
        top: 0.7rem;
        right: 1rem;
    }
    .navbar_burger:hover{
        cursor: pointer;
    }
    .burger-bar, .burger-bar::before, .burger-bar::after{
        display: block;
        width: 40px;
        height: 3px;
        position: relative;
        border-radius: 3px;
        background: #fff;
        transition: all .5s ease-in-out;
    }
    .burger-bar::before, .burger-bar::after{
        content: "";
        position: absolute;
        left: 0;
    }
    .burger-bar::before{
        transform: translateY(-12px);
    }
    .burger-bar::after{
        transform: translateY(12px);
    }
    .show-nav .burger-bar::before{
        transform: rotate(45deg);
    }
    .show-nav .burger-bar{
        background: transparent;
        width: 0;
    }
    .show-nav .burger-bar::after{
        transform: rotate(-45deg);
    }
    /*------Effets Bonus-------*/
    .navbar_item{
        transform: translateY(100vh);
    }
    .show-nav .navbar_item{
        transform: translateY(0);
    }
    .show-nav .slideInDown-1{
        transition: all 1s ease-out;
    }
    .show-nav .slideInDown-2{
        transition: all 1.1s ease-out;
    }
    .show-nav .slideInDown-3{
        transition: all 1.2s ease-out;
    }
    .show-nav .slideInDown-4{
        transition: all 1.3s ease-out;
    }
    .show-nav .slideInDown-5{
        transition: all 1.4s ease-out;
    }
}