body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333;
}
* {
  margin: 0;
  padding: 0;
}
textarea{
  resize: none;
}
a{
  text-decoration: none;
}
a:hover{
  cursor: pointer;
}
ul{
  list-style: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.loader{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 55px;
  height: 100vh;
  color: white;
}
.toast-message {
  font-size: 45px;
  padding: 20px;
  height: 400px;
}
.img{
  background: no-repeat center/cover;
  position: relative;
  height: auto;
  min-height: 100vh;
}
