@keyframes arrow {
    0%{
        opacity: 1;
    }
    12.5%{
        opacity: 0.75;
    }
    25%{
        opacity: 0.5;
    }
    37.5%{
        opacity: 0.25;
    }
    50%{
        opacity: 0;
    }
    62.5%{
        opacity: 0.25;
    }
    75%{
        opacity: 0.5;
    }
    92.5%{
        opacity: 0.75;
    }
    100%{
        opacity: 1;
    }

}

.carousel{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100vh;
    width: 100%;
    animation: fade 3s forwards;
}

.carousel_wrapper{
    position: relative;
    width: 100%;
    height: 100%;
}

.carousel_card{
    display: flex;
    flex: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transform: opacity(0.8);
    transition: 1s ease-out;
}
.carousel_card-active{
    opacity: 1;
    transform: opacity(1);
    pointer-events: visible;
}
.card_image{
    width: 100%;
    object-fit: cover;
}
.card-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}
.card_title{
    color: white;
    font-size: 80px;
    text-align: center;
}
.arrow{
    font-size: 80px;
    color: white;
    position: relative;
    animation: arrow 3s ease-out  infinite;
}
.arrow:hover{
    cursor: pointer;
}
.arrowcontainer{
    position: absolute;
    width: 100%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 868px) {
    .card_title{
        font-size: 50px;
    }
    .arrowcontainer{
        height: 170%;
    }
    .arrow{
        font-size: 60px;
    }
}
@media screen and (max-width: 416px) {
    .card_title{
        font-size: 40px;
    }
}